<template>
  <div class="withdrawDetails">
    <Header title="提现明细" />
    <div class="detailMain">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="detailItem" v-for="item in list" :key="item.id">
          <div class="detailInfo">
            <div class="detailTitle">订单编号：{{ item.id }}</div>
            <div class="copyBtn" @click="copyId(item)">复制</div>
          </div>
          <div class="detailInfo mg20">
            <div class="detailDesc">{{ converType(item.payType) }}提现 - {{ converStatus(item.status) }}</div>
            <div class="money">{{ item.money }}元</div>
          </div>
          <div class="detailInfo">
            <div class="timer">提现时间：{{ item.createdAt | time }}</div>
            <div class="reasonBtn" v-if="item.statusDesc" @click="lookReason(item)">查看详情</div>
          </div>
        </div>
      </PullRefresh>
    </div>
    <div class="actTip">
      <van-overlay :show="showReason">
        <div class="resultBox">
          <div class="title">提现结果</div>
          <div class="result">尊敬的用户：{{ statusDesc }}</div>
          <div class="btn" @click="closeReason">确定</div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import { Overlay, Toast } from 'vant';
import { queryWithdrawDetails } from '@/api/mine';
import Header from '@/components/Header';
import PullRefresh from '@/components/PullRefresh';

export default {
  name: 'withdrawDetails',
  components: {
    PullRefresh,
    [Overlay.name]: Overlay,
    Header,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      showReason: false,
      statusDesc: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(queryWithdrawDetails, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },

    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    copyId(item) {
      this.$copyText(item.id).then(
        (e) => {
          Toast('订单号复制成功');
        },
        (e) => {
          Toast('订单号复制失败');
        },
      );
    },
    lookReason(item) {
      this.statusDesc = item.statusDesc;
      this.showReason = true;
    },
    closeReason() {
      this.showReason = false;
      this.statusDesc = '';
    },
    converType(val) {
      switch (val.toLowerCase()) {
        case 'alipay':
          return '支付宝';
        case 'bankcard':
          return '银行卡';
        case 'usdt':
          return 'USDT';
        default:
          return '其他';
      }
    },
    converStatus(val) {
      switch (val) {
        case 1:
          return '未审核';
        case 2:
          return '审核通过，转账中';
        case 3:
          return '已拒绝';
        case 4:
          return '未知错误';
        case 5:
          return '提现成功';
        case 6:
          return '提现失败';
        default:
          return '未知';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.withdrawDetails {
  height: 100%;
  .detailMain {
    height: calc(100% - 44px);
    .detailItem {
      background-color: #fff;
      padding: 6px 16px;
      margin-top: 10px;
      margin-bottom: 15px;
      border-bottom: 2px solid rgb(244, 244, 244);
      .detailInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detailTitle {
          font-size: 14px;
          color: #666666;
        }
        .copyBtn {
          padding: 2px 8px;
          background-color: #000;
          color: white;
          font-size: 12px;
        }
        .detailDesc {
          width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
        }
        .money {
          color: rgb(255, 103, 143);
          font-size: 20px;
        }
        .timer {
          font-size: 14px;
          color: #000;
        }
        .reasonBtn {
          width: 70px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          box-sizing: border-box;
          background-color: orange;
          color: white;
          font-size: 12px;
        }
      }
      .mg20 {
        margin: 4px 0 6px;
      }
    }
  }

  .actTip {
    /deep/ .van-overlay {
      z-index: 9;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .resultBox {
      background-color: #fff;
      width: 300px;
      padding: 0 14px 14px;
      box-sizing: border-box;
      border-radius: 10px;
      .title {
        font-size: 18px;
        text-align: center;
        padding: 10px 0;
        border-bottom: 2px solid red;
      }
      .result {
        text-align: center;
        padding: 10px 14px 15px;
        font-size: 16px;
      }
      .btn {
        width: 180px;
        height: 38px;
        line-height: 38px;
        border-radius: 38px;
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        background: linear-gradient(to right, rgb(247, 74, 130), rgb(252, 169, 132));
      }
    }
  }
}
</style>
